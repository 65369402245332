<template>
  <overlay
    :show="loading"
  >
    <page-header
      screen-name="Editar Usuário"
      :link-items="linkItems"
    />

    <Form
      v-if="!loading"
      mode="update"
    />
  </overlay>
</template>

<script>

import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { warningMessage } from '@/libs/sweetalerts'
import Form from './Form.vue'
import { getCompanyUserId } from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'

export default {
  components: {
    Overlay,
    PageHeader,
    Form,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          active: true,
          routeName: 'usuario-empresa-list',
        },
        {
          name: '...',
          active: true,
        },
      ],

      moment,

      loading: true,
    }
  },

  computed: {
    getItemInStore() {
      return this.$store.getters['companyUserState/getCompanyUserUpdate']
    },
  },

  created() {
    if (!this.getItemInStore) {
      this.redirectToMainPage()

      return false
    }

    this.$store.commit('companyUserState/clearFormData')

    return this.handleGetCompanyUserId()
  },

  methods: {
    async handleGetCompanyUserId() {
      this.loading = true

      await getCompanyUserId(this.getItemInStore.id_usuario)
        .then(response => {
          const {
            id_usuario,
            nome,
            email,
            cpf,
            data_nascimento,
            sexo,
            empresa,
            perfil,
            id_nacionalidade,
            nome_mae,
            grupos,
          } = response.data

          this.$store.commit('companyUserState/setFormData', {
            id: id_usuario,
            nome,
            email,
            cpf,
            dataNascimento: moment(data_nascimento).format('DD/MM/YYYY'),
            sexo: { name: sexo },
            perfil: perfil[0],
            empresas: empresa,
            idNacionalidade: id_nacionalidade,
            nomeMae: nome_mae,
            grupos: grupos,
          })

          this.pageTitle = nome
          this.linkItems[1].name = nome
        })
        .catch(() => {
          warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
        })

      this.loading = false
    },

    redirectToMainPage() {
      this.$store.commit('companyUserState/setCompanyUserUpdate', null)
      this.$router.replace({ name: 'usuario-empresa-list' })

      return false
    },
  },
}
</script>
